
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { Role } from '@/interfaces/models/Role';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Permission } from '@/enums/Permission';

@Component({
  components: { VTable },
})
export default class RoleTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Role[];
  public headers: ColumnHeader[] = headers;

  public edit(item: Role) {
    this.$router.push({ name: 'role.edit', params: { id: item._id } });
  }

  public editable(item: Role) {
    return !item.isSystem && this.$can(Permission.ROLE_UPDATE);
  }
}
