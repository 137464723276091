
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import RoleTable from '@/components/role/RoleTable.vue';

const role = namespace('role');

@Component({
  components: { RoleTable, VEmptyState, VWrapper },
})
export default class Role extends Vue {
  @role.State('items') public roles!: Role[];
  @role.Action('fetch') public getRoles!: any;
  @role.Action('delete') public deleteRole!: any;

  public async mounted() {
    this.$startLoading('role');
    await this.getRoles();
    this.$stopLoading('role');
  }
}
