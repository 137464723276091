export default [
  {
    text: 'customerGroupProprty.table.name',
    value: 'customerGroupName',
  },
  {
    text: 'customerGroupProprty.table.template',
    value: 'getCustomerInvoiceTemplate',
  },
  {
    text: 'customerGroupProprty.table.defaultOrderStatus',
    value: 'getDefaultOrderStatus',
  },
  {
    text: 'customerGroupProprty.table.initialOrderStatusStandard',
    value: 'getInitialOrderStatusStandard',
  },
  {
    text: 'customerGroupProprty.table.initialOrderStatusTerminal',
    value: 'getInitialOrderStatusTerminal',
  },
];
