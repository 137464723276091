
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import headers from './headers';
import { CustomerGroupProperty } from '@/interfaces/models/CustomerGroupProperty';

@Component({
  components: { VTable },
})
export default class CustomerGroupProprtyTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: CustomerGroupProperty[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: ColumnHeader[] = headers;

  public edit(item: CustomerGroupProperty) {
    this.$router.push({ name: 'customerGroupProprty.edit', params: { id: item._id } });
  }
}
