export default [
  {
    text: 'role.table.name',
    value: 'name',
  },
  {
    text: 'role.table.slug',
    value: 'slug',
  },
  {
    text: 'role.table.permissions',
    value: 'permissions',
    type: 'array',
  },
  {
    text: 'role.table.isSystem',
    value: 'isSystem',
    type: 'boolean',
  },
];
