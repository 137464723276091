
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { CustomerGroupProperty } from '@/interfaces/models/CustomerGroupProperty';
import CustomerGroupProprtyTable from '@/components/customerGroupProperty/CustomerGroupPropertyTable.vue';

const customerGroupsProperty = namespace('customerGroupsProperty');

@Component({
  components: { VWrapper, CustomerGroupProprtyTable },
})
export default class CustomerGroup extends Vue {
  @customerGroupsProperty.Action('fetch') public getCustomersGroups!: any;
  @customerGroupsProperty.State('items') public items!: CustomerGroupProperty[];

  public async mounted() {
    await this.getCustomersGroups({ page: 1 });
  }
}
